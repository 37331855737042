import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { MButton } from 'components/MButton/MButtton';
import { ThemeSwitcher } from 'components/ThemeButton/ThemeButton';
import { MText } from 'components/Typography/MText/MText';
import { Socials } from 'components/templates/Socials/Socials';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import {
  exploreHeaderLinks,
  getInvolvedHeaderLinks,
  learnHeaderLinks,
  litepaperNav,
} from 'layout/Header/header.const';
import sharedStyles from 'styles/sharedStyles.module.css';

import { BurgerExpanderItem } from './components/BurgerExpanderItem';
import styles from './mobileMenu.module.css';

type Props = {
  open: boolean;
  closeMobileMenu: () => void;
  isActiveThemeButton?: boolean;
};

const expandeditemsRecord = {
  0: false,
  1: false,
  2: false,
};

export const MobileMenu = ({ open, closeMobileMenu, isActiveThemeButton = false }: Props) => {
  const [expandedItemsRecord, setExpandedItemsRecord] = useState(() => ({
    ...expandeditemsRecord,
    0: true,
  }));

  const toggleMenuItem = useCallback(
    (idx: number) => {
      if (expandedItemsRecord[idx as keyof typeof expandeditemsRecord]) {
        setExpandedItemsRecord({ ...expandeditemsRecord, [idx]: false });
      } else {
        setExpandedItemsRecord({ ...expandeditemsRecord, [idx]: true });
      }
    },
    [expandedItemsRecord]
  );

  useEffect(() => {
    if (open) {
      document.body.classList.add('no-scroll');
    } else if (!open) {
      document.body.classList.remove('no-scroll');
    }
  }, [open]);

  return (
    <div
      className={classNames(styles.mobileMenuWrapper, sharedStyles.scrollBlock, {
        [styles.menuOpen]: open,
        [styles.menuClosed]: !open,
      })}
    >
      <div className={styles.header}>
        <Link to="/" className={styles.logos}>
          <Icon id="mavryk-text" className={styles.logoMavryk} />
        </Link>

        <button onClick={closeMobileMenu}>
          <CloseIcon />
        </button>
      </div>

      <div className={styles.headerBody}>
        <nav className={styles.navigation}>
          <BurgerExpanderItem
            text="Learn"
            items={learnHeaderLinks}
            isOpen={expandedItemsRecord[0]}
            toggleMenuItem={toggleMenuItem}
            serialNumber={0}
          />
          <BurgerExpanderItem
            text="Explore"
            items={exploreHeaderLinks}
            isOpen={expandedItemsRecord[1]}
            toggleMenuItem={toggleMenuItem}
            serialNumber={1}
          />
          <BurgerExpanderItem
            text="Get Involved"
            items={getInvolvedHeaderLinks}
            isOpen={expandedItemsRecord[2]}
            toggleMenuItem={toggleMenuItem}
            serialNumber={2}
          />

          <div>
            <Link key={litepaperNav.title} to={litepaperNav.link}>
              <Heading className={styles.link} type="none">
                {litepaperNav.title}
              </Heading>
            </Link>
          </div>

          {isActiveThemeButton && (
            <div>
              <ThemeSwitcher />
            </div>
          )}
        </nav>

        <div className={styles.footerSection}>
          <div className={styles.btnWrapper}>
            <Link to="/testnet">
              <MButton variant="contained">
                <MText>Join the Testnet</MText>
              </MButton>
            </Link>
          </div>
          <Socials />
        </div>
      </div>
    </div>
  );
};
