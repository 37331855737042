import React from 'react';

import classNames from 'classnames';

import styles from './mText.module.css';

export type MtextSizeType = 'largeBody' | 'body' | 'smallBody' | 'tinyBody' | 'header' | 'subHeader';

type Props = {
  children: React.ReactNode;
  size?: MtextSizeType;
  weight?: 'regular' | 'bold' | 'semibold' | 'medium';
  className?: string;
};

export function MText({ children, size = 'body', weight = 'regular', className }: Props) {
  return <span className={classNames(styles[size], styles[weight], className)}>{children}</span>;
}
