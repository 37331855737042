import React from 'react';

import classNames from 'classnames';
import { Icon } from 'components/Icon/Icon';

import { socialMedias } from './socials.const';
import styles from './socials.module.css';

export const Socials = (props: { pulsation?: boolean }) => {
  return (
    <div className={styles.socialMedia}>
      {socialMedias.map((item) => {
        if (item.name === 'telegram' && props.pulsation)
          return (
            <a
              key={item.link}
              href={item.link}
              rel="noreferrer"
              target="_blank"
              className={styles.wrapper}
            >
              <Icon id={item.name} />
              <div className={classNames(styles.pulsation)}></div>
              <div className={classNames(styles.pulsation)}></div>
              <div className={classNames(styles.pulsation)}></div>
            </a>
          );
        return (
          <a key={item.link} href={item.link} rel="noreferrer" target="_blank">
            <Icon id={item.name} />
          </a>
        );
      })}
    </div>
  );
};
