import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
// components
import { Icon } from 'components/Icon/Icon';
import { MHeading } from 'components/Typography/MText/MHeading';
import { MText } from 'components/Typography/MText/MText';
import { DesktopOnly, MobileOnly } from 'components/atoms/MediaQueryBlocks/MediaQueryBlocks';
import { Socials } from 'components/templates/Socials/Socials';
// styles
import sharedStyles from 'styles/sharedStyles.module.css';

// consts
import { footerNavigationData, FooterNavItem } from './footer.const';
import styles from './footer.module.css';

type FooterNavBlockProps = {
  title: string;
  items: FooterNavItem[];
};

const SocialMedia = () => {
  return (
    <div className={styles.footerNavBlock}>
      <MHeading level={'h5'} className={styles.title}>
        Socials
      </MHeading>
      <Socials />
    </div>
  );
};

const FooterNavBlock = ({ title, items }: FooterNavBlockProps) => {
  return (
    <div className={styles.footerNavBlock}>
      <MHeading level={'h5'} className={styles.title}>
        {title}
      </MHeading>
      <div className={classNames(styles.footerNavBlockValues)}>
        {items.map((item) => (
          <a
            key={item.title}
            href={item.httpLink}
            rel="noreferrer"
            target="_blank"
            className={styles.link}
          >
            <MText className={styles.linkText}>{item.title}</MText>
          </a>
        ))}
      </div>
    </div>
  );
};

export const Footer = () => {
  const navigate = useNavigate();
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className={styles.footerBg}>
      <div className={sharedStyles.container}>
        <div className={styles.footer}>
          <div className={styles.footerContent}>
            <div className={styles.logo}>
              <button className={styles.logoInner} onClick={() => navigate('/')}>
                <Icon id="logo" className={styles.logoIcon} />
                <Icon id="mavryk-text" className={styles.logoMavryk} />
              </button>
            </div>
            <div className={styles.footerInner}>
              <MobileOnly>
                <SocialMedia />
              </MobileOnly>
              {Object.entries(footerNavigationData).map(([title, values]) => (
                <FooterNavBlock key={title} title={title} items={values} />
              ))}
              <DesktopOnly>
                <SocialMedia />
              </DesktopOnly>
            </div>
          </div>

          <div className={styles.copyrightBlock}>
            <MText size="smallBody" weight="bold" className={styles.copyrightBlockText}>
              © {year} Mavryk Dynamics. All Rights Reserved
            </MText>
          </div>
        </div>
      </div>
    </footer>
  );
};
