import React from 'react';

import classNames from 'classnames';

import styles from './mText.module.css';

type Props = {
  children: React.ReactNode;
  level?: 'hero' | 'card' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  className?: string;
};

export function MHeading({ children, level = 'h1', className }: Props) {
  return <div className={classNames(className, styles[level])}>{children}</div>;
}
