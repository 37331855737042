import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import LogoAnimation from 'lottie/logo.json';

import classNames from 'classnames';
// components
import { Icon } from 'components/Icon/Icon';
import { MButton } from 'components/MButton/MButtton';
import { MText } from 'components/Typography/MText/MText';
import { Socials } from 'components/templates/Socials/Socials';
import {
  AnimationItemMethods,
  LottieHookOptions,
  useLottieAnimation,
} from 'hooks/lottie/useLottieAnimation';
import { lockBodyScroll } from 'utils/lockBodyScroll';

import { MobileMenu } from '../templates/Menu/MobileMenu';
import { HeaderLinkList } from './components/HeaderLinkList';
// helpers
import {
  litepaperNav,
  LOGO_ANIMATION_NAME,
  SECONDARY_HEADER_Y_OFFSET, // pagesWithoutStayTunedButton,
} from './header.const';
// styles
import styles from './header.module.css';

const lottieMethods: AnimationItemMethods = {
  setSpeed: [1],
};

type HeaderProps = {
  ghostHeight?: boolean;
  alternativeDesign?: boolean;
};

export const Header: FC<HeaderProps> = ({ ghostHeight = false, alternativeDesign = false }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [showSecondaryHeader, setShowSecondaryHeader] = useState(() => alternativeDesign);

  const lottieOptions: LottieHookOptions = useMemo(
    () => ({
      animationData: LogoAnimation,
      loop: false,
      autoplay: false,
      renderer: 'svg',
      name: LOGO_ANIMATION_NAME,
      // current animation has 120 frames and starting from 75th frame it does nothing
      // so we cut it off
      // for alternative design we dont play anbimation, so we set inital frame at 55
      initialSegment: [alternativeDesign ? 55 : 0, 55],
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    [alternativeDesign]
  );

  const handleBurgerClose = useCallback(() => setIsOpen(false), []);

  const { lottieRefCallbak, animationInstanceRef } = useLottieAnimation<HTMLDivElement>(
    LOGO_ANIMATION_NAME,
    lottieOptions,
    lottieMethods
  );

  useEffect(() => {
    const scrollContainer = window;

    const listenerCb = (e: Event) => {
      const scrollOffest = (scrollContainer as Window).pageYOffset;
      const isBeyondOffset = scrollOffest > SECONDARY_HEADER_Y_OFFSET;

      if (isBeyondOffset) {
        animationInstanceRef.current?.setDirection(1);
        animationInstanceRef.current?.play();

        // 54 - last cutted animation frame (see lottieOptions above)
      } else if (scrollOffest < SECONDARY_HEADER_Y_OFFSET) {
        animationInstanceRef.current?.play();
        animationInstanceRef.current?.setDirection(-1);
      }

      setShowSecondaryHeader(isBeyondOffset);
    };

    if (scrollContainer && !alternativeDesign) {
      scrollContainer.addEventListener('scroll', listenerCb);
    }

    // if (alternativeDesign) {
    animationInstanceRef.current?.goToAndStop(74, true);
    // }

    return () => {
      scrollContainer.removeEventListener('scroll', listenerCb);
    };
  }, [alternativeDesign, animationInstanceRef]);

  useEffect(() => {
    // close burger when navigating to other page on mobile
    setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    lockBodyScroll(isOpen);
    return () => {
      lockBodyScroll(false);
    };
  }, [isOpen]);

  // styles conditions
  const displaySecondaryHeader = showSecondaryHeader && !alternativeDesign;
  const addOffset = showSecondaryHeader || alternativeDesign;

  return (
    <>
      <div
        className={classNames(
          styles.headerWrapper,
          alternativeDesign && styles.alternativeHeaderWrapper,
          displaySecondaryHeader && styles.reducedPadding
        )}
      >
        <header className={styles.header}>
          <div
            className={classNames(
              styles.navWrapper,
              (showSecondaryHeader || alternativeDesign) && styles.secondaryNavWrapper
            )}
          >
            <button
              className={styles.logo}
              onClick={() => (pathname !== '/' ? navigate('/') : undefined)}
            >
              <div className={classNames(styles.logoWrapper)}>
                <div ref={lottieRefCallbak} />
              </div>
            </button>

            <div
              className={classNames(
                styles.navigation,
                showSecondaryHeader && styles.secondaryNavigation
              )}
            >
              {showSecondaryHeader && (
                <img
                  draggable={false}
                  src="/assets/general/header-list-bg.svg"
                  alt="list bg"
                  className={styles.secondaryListImg}
                />
              )}
              <HeaderLinkList addOffset={addOffset} />

              <Link key={litepaperNav.title} to={litepaperNav.link} className={styles.litepaper}>
                <MText size="body">{litepaperNav.title}</MText>
              </Link>
            </div>
          </div>

          <button
            onClick={() => setIsOpen(!isOpen)}
            className={classNames(styles.burgerMenu, {
              [styles.menuIsOpen]: isOpen,
            })}
          >
            <Icon id="menu" />
          </button>
          <div
            className={classNames(
              styles.headerRightPanel,
              showSecondaryHeader && styles.secondaryRightPanel
            )}
          >
            <Socials pulsation />
            <Link to="/testnet">
              <MButton variant="contained" className={styles.navigationBtn}>
                Join the Testnet
              </MButton>
            </Link>
          </div>
        </header>

        <MobileMenu open={isOpen} closeMobileMenu={handleBurgerClose} />
      </div>
      {ghostHeight && <div className={styles.ghostHeight} />}
    </>
  );
};
