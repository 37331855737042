// social media
export const MAVRYK_TELEGRAM_URL = 'https://t.me/+skFJjewPdRU5ZGQ0';
export const MAVRYK_GITHUB_URL = 'https://github.com/mavryk-network/';
export const MAVRYK_TWITTER_URL = 'https://twitter.com/MavrykNetwork';
export const MAVRYK_DISCORD_URL = 'https://discord.gg/FXYQyVf6fE';
export const MAVRYK_MEDIUM_URL = 'https://blog.mavryk.org/';
export const MAVRYK_LINKEDIN_URL = 'https://www.linkedin.com/company/mavryk-network/';

// wallet extension
export const WALLET_LINK = 'https://github.com/mavryk-network/templewallet-extension';

export const FAUCET_LINK = 'https://atlasnet.faucet.mavryk.network/';
export const NEXUS_LINK = 'https://nexus.mavryk.org/';
export const EQUITEEZ_LINK = 'https://equiteez.com/';
export const MAVEN_LINK = 'https://mavenfinance.io/';

export const TESTNET_FEEDBACK =
  'https://mavrykdynamics.notion.site/13fa7b1276ae8079aaabfa97ae0c3a38?pvs=105';

export const JOIN_US_LINK =
  'https://mavrykdynamics.notion.site/15fa7b1276ae801ea092de8a6d4def0f?pvs=105';
