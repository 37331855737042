import {
  MAVRYK_DISCORD_URL,
  MAVRYK_GITHUB_URL,
  MAVRYK_LINKEDIN_URL,
  MAVRYK_MEDIUM_URL,
  MAVRYK_TELEGRAM_URL,
  MAVRYK_TWITTER_URL,
} from 'consts/url.const';

// uncomment sections to test flexibility
export const socialMedias = [
  { name: 'twitter', link: MAVRYK_TWITTER_URL },
  { name: 'telegram', link: MAVRYK_TELEGRAM_URL },
  { name: 'discord', link: MAVRYK_DISCORD_URL },
  { name: 'github', link: MAVRYK_GITHUB_URL },
  { name: 'medium', link: MAVRYK_MEDIUM_URL },
  { name: 'linkedin', link: MAVRYK_LINKEDIN_URL },
];
